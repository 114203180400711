.chat {
  padding: 8px;
}

.MessageBoxInput {
  width: 100%;
  background-color: #f9d88c;
  margin-top: 12px;
  padding: 8px;
}

.MessageBox {
  color: #222;
  width: 98%;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 12px 16px;
}

.MessageBox .options {
  text-align: right;
}

.MessageBox .footer {
  text-align: right;
  margin-top: 12px;
}

.time {
  color: #aaa;
  float: left;
  padding-top: 8px;
  font-size: 12px;
}

.Customer {
  background-color: #ffe8e8;
  align-self: flex-end;
}

.Agent {
  background-color: #f9d88c;
  align-self: flex-start;
}

.System {
  color: #fff;
  color: #202020;
  background-color: #db8337;
  border-radius: 0;
}

.Info {
  color: #80878d;
  text-align: center;
  width: 80%;
  box-shadow: none;
  background-color: #f7f8dd;
  border-radius: 2px;
  margin: 2px auto;
  padding: 4px;
  font-size: 12px;
}

.MessageBox .footer {
  border-top: 1px dotted #888;
}

.System .time {
  color: #222;
}

/*# sourceMappingURL=index.9c720f1e.css.map */
