.chat {
    /* border: 3px dotted red; */
    padding: 8px;
}

.MessageBoxInput {
    width: 100%;
    background-color: #f9d88c;
    padding: 8px;
    margin-top: 12px;
}
.MessageBox {
    color: #222;
    border-radius: 4px;
    padding: 12px 16px;
    width: 98%;
    margin-bottom: 8px;
    width: 100%;
}

.MessageBox .options {
    text-align: right;
}

.MessageBox .footer {
    text-align: right;
    margin-top: 12px;
}

.time {
    color: #aaa;
    font-size: 12px;
    float: left;
    padding-top: 8px;
}

.Customer {
    align-self: flex-end;
    background-color: #ffe8e8;
}

.Agent {
    align-self: flex-start;
    background-color: #f9d88c;
}

.System {
    color: #fff;
    background-color: #db8337;
    border-radius: 0;
    /* width: 98%; */
    color: #202020;
}

.Info {
    color: #80878d;
    background-color: #f7f8dd;
    font-size: 12px;
    text-align: center;
    padding: 4px;
    border-radius: 2;
    width: 80%;
    margin: 2px auto;
    box-shadow: none;
}

.MessageBox .footer {
    border-top: 1px dotted #888;
}

.System .time {
    color: #222;
}